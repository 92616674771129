import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bd714dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative w-full flex-col" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id", "name", "tabindex"]
const _hoisted_4 = ["id", "disabled", "onUpdate:modelValue", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: _ctx.id,
      class: "flex flex-shrink-0 justify-center"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.length, (index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["justify-center items-center rounded-md mr-3", `${_ctx.computedCodeInputStyle.widthClasses} ${_ctx.computedCodeInputStyle.heightClasses}`]),
          id: _ctx.id + index,
          name: _ctx.name + index,
          tabindex: _ctx.isDisabled ? -1 : index
        }, [
          _withDirectives(_createElementVNode("input", {
            id: 'codeInput' + index,
            ref_for: true,
            ref: _ctx.setInputRef,
            disabled: _ctx.isDisabled,
            type: "text",
            min: "0",
            class: _normalizeClass(["h-full w-full border-2 rounded-lg text-center outline-none", 
            (_ctx.codeValue[index - 1]
              ? 'border-' + _ctx.computedCodeInputStyle.activeBorderColor
              : 'border-' + _ctx.computedCodeInputStyle.borderColor) +
            ' bg-' +
            _ctx.computedCodeInputStyle.backgroundColor +
            ' text-' +
            _ctx.computedCodeInputStyle.textColor +
            ' focus:border-' +
            _ctx.computedCodeInputStyle.focusBorderColor +
            ' hover:border-' +
            _ctx.computedCodeInputStyle.hoverBorderColor +
            ' text-' +
            _ctx.computedCodeInputStyle.textSize
          ]),
            maxlength: "1",
            "onUpdate:modelValue": ($event: any) => ((_ctx.codeValue[index - 1]) = $event),
            onInput: ($event: any) => (_ctx.handleInputEvent($event, index))
          }, null, 42, _hoisted_4), [
            [_vModelText, _ctx.codeValue[index - 1]]
          ])
        ], 10, _hoisted_3))
      }), 128))
    ], 8, _hoisted_2),
    _createVNode(_component_meta_handler, {
      inputMeta: _ctx.metaInformation,
      metaStyle: _ctx.inputStyle
    }, null, 8, ["inputMeta", "metaStyle"])
  ]))
}